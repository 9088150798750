.home {
    background: url('/images/back-texture.png') no-repeat center center / cover, $altGray;
    overflow: hidden;

    min-height: 100vh;

    &__hero {
        padding-bottom: 80px;

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__vtr-logo {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &__wrapper {
            padding-top: 40px;
            
            @include mq(md) {
                padding-top: 80px;
            }
        }

        &__list {
            margin-bottom: 40px;            
            @include mq(md) {
                margin-bottom: 80px;
                display: flex;
                justify-content: center;
                align-items: center;

            }
        }

        &__image {
            width: 100%;

            img {
                width: 100%;
            }
        }
        
        &__item {
            text-align: center;
            margin-bottom: 5px;

            @include mq(md) {
                margin: 0 20px;
            }

            a {
                text-transform: uppercase;
                text-decoration: none;
                color: $white;
                font-weight: 500;
                font-size: 20px;
                text-shadow: 
                    -2px -2px 0 #000,  
                    2px -2px 0 #000,
                    -2px 2px 0 #000,
                    2px 2px 0 #000;
                
                &:hover {
                    color: $white - #666;
                }
            }
        }

        &__lolla-logo {
            padding-top: 10px;
            
            @include mq(md) {
                padding-top: 20px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &__content {
        padding-bottom: 100px;

        &__wrapper {
            width: 100%;

            &--top {
                background: url('/images/paper-top.png') no-repeat center bottom / contain;
                padding-top: 20px;
                padding-bottom: 10px;
         
                @include mq(md) {
                    padding-top: 80px;
                    padding-bottom: 40px;
                    background: url('/images/paper-top.png') no-repeat center top / cover;
                }

                &.-small {
                    background: url('/images/paper-top.png') no-repeat center top / cover;

                    @include mq(md) {
                        background: url('/images/paper-top.png') no-repeat center top / cover;

                    }
                }
            }

            
            &--middle {
                background: url('/images/paper-middle.png') repeat center center / contain;
                height: 0;
                opacity: 0;
                padding-left: 20px;
                padding-right: 20px;
                
                @include mq(md) {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 60px;
                }
                
                &.-active {
                    padding-bottom: 10px;
                    height: auto;
                    opacity: 1;
                    margin-bottom: -2px;
                    margin-top: -1px;
                }

                &.-small {
                    background: url('/images/paper-middle.png') repeat center center / contain;
                    text-align: center;
                    
                    @include mq(md) {
                        background: url('/images/paper-middle.png') repeat center center / cover;
                    }
                    
                    h1 {
                        font-family: $mainFont;
                        font-weight: 500;
                        color: $magenta;
                        font-size: 16px;
                        margin: 0;
                        padding: 10px 0;
                        width: 100%;

                        @include mq(md) {
                            font-size: 32px;
                        }

                        span {
                            font-family: $altFont;
                            font-weight: bold;
                        }
                    }
                }
            }

            &--bottom {
                overflow: hidden;
                height: 50px;
                background: url('/images/paper-bottom.png') no-repeat center bottom / cover;
                width: 104.4%;
                transform: translateX(-2.8%);

                @include mq(md) {
                    height: 150px;
                }
            }
        }

        &__title {
            color: $magenta;
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            position: relative;
            z-index: 2;
            width: 85%;
            font-size: 15px;

            @include mq(md) {
                width: 55%;
                font-size: 24px;
            }

            span {
                color: $black;
                
                &.-number {
                    font-weight: 600;
                    font-family: $altFont;
                }
            }
        }

        &__group {
            padding: 100px 40px;
            z-index: 2;
            border-radius: 25px;
            height: 80vh;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 40px;
            }

            &.-hidden {
                opacity: .3;
                pointer-events: none;
            }

            @include mq(md) {
                width: 32.5%;
                height: 40vw;

            }

            &--magenta {
                background: $magenta;
            }
            &--green {
                background: $green;
            }
            &--yellow {
                background: $yellow;
                padding: 100px 40px 50px 40px;
            }
        }

        &__image {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        &__container {
            width: 100%;
            border-radius: 25px;
            position: relative;
            padding: 10px 10px 70px 10px;
            
            @include mq(md) {
                height: 620px;
                padding: 20px 60px 0 60px;
            }

            &--general {
                background: $magenta;

                .home__content__buy-button {
                    color: $black;
                    background: $yellow;
                    box-shadow: 0 5px 0 1px ($yellow - #333);
                }

                .home__content__close-button {
                    background: transparentize($magenta - #333, .3);
                }

                .home__content__table-row:nth-last-child(even) {
                    background: transparentize($magenta - #333, .3);
                }

                .home__content__table-cell {
                    p,
                    .-color {
                        color: $yellow;
                        font-weight: 900;
                    }
                }
            }

            &--lounge {
                background: $green;

                .home__content__buy-button {
                    background: $orange;
                    color: $white;
                    box-shadow: 0 5px 0 1px ($orange - #333);
                }
                .home__content__close-button {
                    background: transparentize($green - #333, .3);
                }

                .home__content__table-row:nth-last-child(odd) {
                    background: transparentize($green - #333, .3);
                }

                .home__content__table-cell {
                    p,
                    .-color {
                        color: $yellow;
                        font-weight: 900;
                    }
                }
            }

            &--lounge-premium {
                background: $yellow;

                .home__content__buy-button {
                    background: $purple;
                    color: $white;
                    box-shadow: 0 5px 0 1px ($purple - #333);
                }

                .home__content__close-button {
                    background: transparentize($yellow - #333, .3);
                }
            
                .home__content__table-row:nth-last-child(odd) {
                    background: transparentize($yellow - #333, .3);
                }
            
                .home__content__table-cell {
                    p,
                    .-color {
                        color: $purple;
                        font-weight: 900;
                    }
                }
            }
        }

        &__container-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;

            img {
                width: 80%;

                @include mq(md) {
                    width: 100%;
                }
            }
        }

        &__close-button {
            position: relative;
            height: 30px;
            width: 30px;
            border: none;
            border-radius: 50%;
            outline: none;
            cursor: pointer;

            @include mq(md) {
                height: 50px;
                width: 50px;
            }

            span {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 15px;
                width: 3px;
                background: $white;
                border-radius: 50px;
                
                @include mq(md) {
                    height: 20px;
                    width: 4px;
                }

                &.-left {
                    transform: translate3d(-50%, -50%, 0) rotate(-40deg)
                }

                &.-right {
                    transform: translate3d(-50%, -50%, 0) rotate(40deg)
                }
            }
        }

        &__table {
            // display: flex;
            overflow: hidden;

        }

        &__table-row {
            display: flex;
            flex-wrap: wrap;
            border-radius: 10px;

            &--headings {
                display: none;
                
                @include mq(md) {
                    justify-content: flex-end;
                    display: flex;
                    height: 40px;
                }
            }

            &.-hidden {
                opacity: .3;
            }
        }

        &__table-cell {
            text-align: right;
            color: $white;
            font-family: $altFont;
            padding-top: 10px;
            height: 65px;
            margin-bottom: 10px;

            @include mq(md) {
                margin-bottom: 0;
                height: 60px;
            }

            &:nth-child(even) {
                text-align: left;
                padding-left: 20px;

                @include mq(md) {
                    text-align: right;
                }
            }
            
            &:nth-child(odd) {
                padding-right: 20px;

                @include mq(md) {
                    padding-right: 0;
                    text-align: right;
                }
            }

            &:first-child {
                padding-left: 20px;

            }

            &:last-child {
                padding-right: 20px;
            }

            h3 {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 16px;
                margin: 0;
            }

            p {
                font-weight: 900;
                font-size: 16px;
                margin: 0;
            }

            h4 {
                margin: 0;
                font-weight: 900;
                text-transform: uppercase;
                font-size: 14px;
            }

            label {
                font-size: 12px;
                font-weight: 900;
                text-transform: uppercase;

                @include mq(md) {
                    display: none;
                }
            }

            span {
                display: block;
            }

            &--row-title {
                text-align: left !important;
                width: 100%;
                height: 40px;

                @include mq(md) {
                    width: 30%;
                    height: auto;
                }
            }

            &--stock {
                width: 60%;

                @include mq(md) {
                    width: 15%;
                }
            }
        
            &--price {
                width: 40%;
                
                @include mq(md) {
                    width: 15%;
                }
            }

            &--service {
                width: 60%;

                @include mq(md) {
                    width: 20%;
                }
            }

            &--final-price {
                width: 40%;

                @include mq(md) {
                    width: 20%;
                }
            }
        }

        &__table-container {
            display: flex;
            width: 200px;
            overflow: auto;

            @include mq(md) {
                width: 70%;
            }
        }

        &__buy-button {
            position: absolute;
            left: 50%;
            bottom: -25px;
            height: 60px;
            line-height: 60px;
            transform: translateX(-50%);
            z-index: 2;
            border-radius: 5px;
            text-transform: uppercase;
            color: $black;
            font-family: $altFont;
            text-decoration: none;
            font-weight: 900;
            padding: 0 20px;
            white-space: nowrap;

            @include mq(md) {
                padding: 0 40px;

            }
        }
    }
}
